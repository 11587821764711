.overlay {
    opacity: 0.8;
    background-color: #b3b4e5;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}